import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallery"
import { formatGraphQLImagesToGallery } from "../utils/gallery"
import { Flex, Box, Heading } from "rebass"
import styled from "styled-components"
import Quote from "../components/quote"
import ShareButtons from "../components/shareButtons"
import Divider from "../components/divider"
import {
  BuyCallToAction,
  BootsToBlissAmazonBuyButton,
  Paragraph,
  Testimonial,
} from "../components"

const StyledGatsbyImage = styled(GatsbyImage)`
  margin: 2em 0;
`

const BootsToBliss = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      bookCover: file(relativePath: { eq: "boots-to-bliss-book-cover-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      openGraphImage: file(
        relativePath: { eq: "boots-to-bliss-og-image.png" }
      ) {
        childImageSharp {
          fixed(width: 1146) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      map: file(relativePath: { eq: "boots-to-bliss-map.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "boots-to-bliss" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const social = {
    description:
      "64 years old and walking over 2500km alone. The intriguing story of a 21st century pilgrim who walked The way of Saint James",
    title: "Boots to Bliss by Claude Tranchant",
    image: `${data.site.siteMetadata.siteUrl}${data.openGraphImage.childImageSharp.fixed.src}`,
    url: `${data.site.siteMetadata.siteUrl}/boots-to-bliss`,
  }

  const testimonials = [
    {
      by: "Erica",
      testimony:
        "Well, Well, Well! What a Journey! What a book! it is such a privilege to read your story about your journey along the Saint James' Way........ you described in your book your journey so vividly that I could picture it so well in my mind and I did walk along side of you each step of the way.......you are a very unique and rare person, your inner strength, determination and self-belief is something to marvel at and admire. It is really a book written from the heart. What a legacy to leave behind for your family.....!",
    },
    {
      by: "T. Madden",
      testimony:
        "I loved your journey. Although I loved the areas you travelled through, your personal journey continues to win me over.........What a marvellous journey, you embarked upon... you have touched so many people... a truly amazing story!",
    },
    {
      by: "R. Collins",
      testimony:
        "I started reading Boots to Bliss on 26th December and finished on the 28th December. This is, I think, testament to how much I enjoyed it... It is a charming book. I loved the way the author interspersed the physical journey with her spiritual journey, the descriptions of the landscape, the historical details and the way she foreshadowed the difficulties to come. Claude Tranchant has combined these elements brilliantly. Boots to Bliss deserves a very wide readership... Congratulations on a really inspirational book.",
    },
  ]

  return (
    <Layout>
      <SEO
        title={social.title}
        description={
          "Boots to Bliss by Claude Tranchant is a book detailing the adventure of this 64 year old woman walking over 2500km alone from Vezelay in France all the way to Santiago de Compostela and beyond. Learn about the history she travelled through and the people she met, even use this information to tailor your own a Camino adventure"
        }
        meta={[
          {
            name: "keywords",
            content:
              "Claude Tranchant, traveller, senior, camino, saint james way",
          },
        ]}
        canonicalUrl={social.url}
        openGraph={{
          namespace: "og: http://ogp.me/ns# book: http://ogp.me/ns/book#",
          type: "books.author",
          meta: {
            description: social.description,
            image: social.image,
            book: {
              author: `${data.site.siteMetadata.siteUrl}/`,
              isbn: "9780646585871",
              release_date: "2012-12-06",
              tag: ["Boots to Bliss", "Travel", "Camino", "Saint James Way"],
            },
          },
        }}
      />
      <Flex flexWrap="wrap">
        <Box
          width={[1, 1, 2 / 3]}
          sx={{
            textAlign: ["center", "center", "left"],
          }}
        >
          <Heading fontSize={[5, 6]}>Boots to Bliss</Heading>
          <Heading fontSize={[2, 3]} mb={3}>
            by Claude Tranchant
          </Heading>
        </Box>
        <Box
          width={[1, 1, 1 / 3]}
          sx={{
            display: "flex",
            justifyContent: ["center", "center", "flex-end"],
            alignItems: "center",
          }}
        >
          <BuyCallToAction
            amazonButton={<BootsToBlissAmazonBuyButton />}
            data-testid={"buy-call-to-action"}
          />
        </Box>
      </Flex>
      <Divider display={["none", "inherit"]} />
      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1 / 3]}>
          <GatsbyImage fluid={data.bookCover.childImageSharp.fluid} />
          <ShareButtons {...social} data-testid={"share-buttons"} />
        </Box>
        <Box p={2} width={[1, 1, 2 / 3]} mb={0}>
          <Quote>'I lived 100 days to the rhythm of my footsteps'</Quote>
          <Paragraph m={0} pl={[0, 2, 4]}>
            On my 64th birthday, I started a journey from Vezelay in the
            Burgundy region, France and I put my feet for the first time on the
            medieval path of saint James – 'The Way'. I crossed France, I went
            over the pyrenees, through northern part of Spain along the Camino
            Frances as far as Santiago de Compostela. From there, I kept on
            walking and I finished at Muxia in the north-west of Galicia
            (Spain). I covered 2500 kilometres, alone, over 100 days and I was
            known along 'The Way' as 'Claude the Australian'.
          </Paragraph>
        </Box>
        <Flex>
          <Box width={1}>
            <Divider />
            {testimonials.map(({ by, testimony }) => (
              <>
                <Testimonial by={by}>{testimony}</Testimonial>
                <Divider />
              </>
            ))}
          </Box>
        </Flex>
        <Box width={1}>
          <StyledGatsbyImage fluid={data.map.childImageSharp.fluid} />
          <Paragraph>
            By reading, Boots to Bliss, I hope you will be inspired, and that
            you will want to walk and discover this majestic path. You will
            realise you are never too old to fulfil a dream. You will learn the
            same way I did, about its historical facts and anecdotes, the towns
            and villages I crossed in France and Spain, and the unbelievable
            encounters I had with people.
          </Paragraph>
          <Paragraph>
            You will learn about my inner thoughts, my self-discovery, my
            internal growth and how I was empowered. I met many pilgrims and I
            became their 'listening ear'. When I returned I was a free, strong
            and determined human being. I had learnt a lot about the human
            spirit and the power of being and to live in the moment.
          </Paragraph>
          <Paragraph>
            The world is getting smaller and smaller, as man has discovered
            nearly everything except himself and life with its merry-go-round
            does not give us a lot of chances to stop and think. The Way of
            Saint James can be a way to help us to re-discover who we really
            are.
          </Paragraph>
          <Paragraph>
            My wish is that my book will flow the same way as my journey on The
            Way of Saint James and that it will touch hearts, even if it touches
            only one heart, my mission will have been fulfilled.
          </Paragraph>
        </Box>
      </Flex>
      <br />
      <Gallery images={formatGraphQLImagesToGallery(data.gallery.edges)} />
    </Layout>
  )
}

export default BootsToBliss
