import React from "react"
import { Heading } from "rebass"

const Quote = ({ children }) => (
  <>
    <Heading
      m={3}
      sx={{
        fontSize: 5,
        color: "light",
        textAlign: "center",
        fontStyle: "italic",
      }}
    >
      {children}
    </Heading>
  </>
)

export default Quote
